import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import PersonDetail from "../components/person-detail"
import LinkBack from "../components/link-back"
import { graphql } from "gatsby"

const VorstandPage = ({ data }) => {
  const board = data.sanityGeneral.board
  return (
    <Layout>
      <Seo title="Vorstand" />
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <p>
            <LinkBack to={"/kontakt"} label={"Kontakt"} />
          </p>
          <h1>Vorstand</h1>
          {board.map(({ name, email, phone, description, image }, index) => (
            <PersonDetail
              key={`board-${index}`}
              className="mb-4"
              image={image}
              name={name}
              phone={phone}
              description={description}
              email={email}
            />
          ))}
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityGeneral {
      board {
        name
        email
        phone
        description
        image {
          alt
          asset {
            gatsbyImage(width: 125, height: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default VorstandPage
